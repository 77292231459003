jQuery(document).ready(function () {
    jQuery('[data-ajax-submit]', this).on('submit', function (event) {
        event.preventDefault();
        event.stopPropagation();


        var $viewport = $(window).width();
        var $messageModal;
        var $this = jQuery(this);
        var $submitButton = $this.find('button[type=submit]');
        var $submitButtonText = $submitButton.text();
        try {
            $messageModal = $this.closest('[data-remodal-id]').remodal();
        } catch ($exception) {
            //no halt
        }


        $.ajax({
            url: $(this).attr('action'),
            type: $(this).attr('method'),
            dataType: 'json',
            data: $(this).serialize(),
            beforeSend: function (jqXHR, settings) {
                //cleanup validation related classes
                jQuery('.help-block').remove();
                $this.find('.form-group').removeClass('has-error has-success');
                $this.siblings('.alert').remove();
                $submitButton.attr('disabled', true).html('<i class="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i> ' +
                    '<span class="sr-only">Loading...</span>');
            }
        }).done(function (data, textStatus, jqXHR) {
            //Verify for a success message
            if (data.message.success) {
                var $track = $('input[name=track]');

                if($track.length === 1) {
                    var google_conversion_id = 829026838;
                    var google_conversion_label = "BlWwCOHvxnoQluSniwM";
                    var google_remarketing_only = false;
                    $.getScript('http://www.googleadservices.com/pagead/conversion.js');
                }

                $this.find('.form-group').addClass('has-success');

                if (typeof $this.data('form-reset') !== 'undefined' && $this.data('form-reset')) {
                    $this[0].reset();
                }

                if ($this.hasClass('do-hide') && (typeof $messageModal === 'object')) {
                    $('form.do-hide').hide();
                    setTimeout(function () {
                        messageModal.close();
                        $('form.do-hide').show();
                        message.removeClass('success error').empty();
                    }, 4000);
                } else if ($this.hasClass('form-hide')) {
                    $this.slideUp();
                }
                try {
                    dataLayer.push({'listing_pagetype': 'conversion'});
                    dataLayer.push({'event': 'PageTypeChange'});
                    dataLayer.push({'event': 'Conversion'});
                } catch ($exception) {
                    console.warn('GTM not instaled.')
                }
                $this.before('<div class="alert alert-success success">' + data.message.message + '</div>');
            } else {
                $this.before('<div class="alert alert-danger error">Algo errado aconteceu com nosso servidor.</div>');
            }
        }).fail(function (jqXHR, textStatus, errorThrown) {

            try {
                var $data = jqXHR.responseJSON;

                $.each($data.message.payload.validation, function (key, value) {
                    var error = '';
                    var $field = $this.find('[name=' + key + ']');
                    $field.parent().addClass('has-error');
                    if (Object.keys(value).length > 1) {
                        $.each(value, function (key, value) {
                            error += '<li>' + value + '</li>';
                        });

                        $field.parent().append(
                            '<div class="help-block"><ul>' + error + '</ul></div>'
                        );
                    } else {
                        $.each(value, function (key, value) {
                            error += value;
                        });

                        $field.parent().append(
                            '<div class="help-block">' + error + '</div>'
                        );
                    }
                });
                $this.before('<div class="alert alert-danger error">' + $data.message.message + '</div>');
            } catch ($exception) {
                alert('Algo errado aconteceu ao enviar o seu formulário.')
            }
        }).always(function () {
            if ($viewport < 700) {
                var $formMessage = $this.siblings('.alert');
                if ($formMessage.length) {
                    $('html, body').animate({scrollTop: ($formMessage.offset().top) - 10}, 300)
                }
            }
            $submitButton.removeAttr('disabled').html($submitButtonText);
        })
    });
});