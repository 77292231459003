$(document).ready(function () {

    $(".tab_content").hide();
    $("ul.tabs li a:first").parent('li').addClass("active").show();
    $(".tab_content:first").show();

    $('ul.tabs li a').click(function () {
        $("ul.tabs li").removeClass("active");
        $(this).parent().addClass("active");
        $(".tab_content").hide();

        var activeTab = $(this).parent('li').find("a").attr("href");
        $(activeTab).fadeIn();
        $('.slideVenda').slick('setPosition');
        $('.slideLocacao').slick('setPosition');

        $('.menuTipoVenda h3 a.active').click();
        $('.menuTipoLocacao h3 a.active').click();

        return false;
    });

    /**
     * slider

     /* TIPO VENDA */
    $(".menuTipoVenda h3 a").click(function () {
        $(".menuTipoVenda h3 a").removeClass("active");
        $(this).addClass("active");
    });
    /* TIPO LOCAÇÃO */
    $(".menuTipoLocacao h3 a").click(function () {
        $(".menuTipoLocacao h3 a").removeClass("active");
        $(this).addClass("active");
    });
    
});