$(document).ready(function () {
    $('.bxSearch input[type=radio]:checked').closest('label').addClass('RadioChecked');

    /*$('.btMenu').click(function () {
        $('.bxMenu').slideToggle();
        $('.bgMenu').slideToggle();
    });

    $('.bgMenu').click(function () {
        $('.bxMenu').slideToggle();
        $('.bgMenu').slideToggle();
    });
*/
    $(".clientela").click(function(){
        $(".digital").toggle();
    });
    /**
     * ordernar na página de resultados
     /* ORDENAR */
    $('.btOrdenar span').click(function () {
        $('.btOrdenar ul').slideToggle();
    });

    $('[data-remodal-target=indique], [data-remodal-target=contatar]').on('click', function (event) {
        $('[name=property_id]').val($(this).data('property-id'));
        console.log($(this));
        if (typeof $(this).data('value') !== 'undefined') {
            $('[name=message]').val($(this).data('value'));
        }
    })

});

$(document).ready(function () {
    var page = window.location.pathname;
    if (page === '/') {
        $('.buscaDaHome').addClass('onlyHome');
    }
});

$(document).ready(function () {
    var page = window.location.pathname;
    var tamanhoViewport = $(window).width();
    $(window).scroll(function () {
        if (page === '/') {
            if ($(document).scrollTop() <= 500) {
                $('.buscaDaHome').removeClass('fixed-top');
                $('.logo-scroll').hide();
                $('.buscaDaHome .bxSearch').show();
            }
            else {
                $('.buscaDaHome').addClass('fixed-top');
                $('.buscaDaHome.fixed-top').slideDown();
                $('.logo-scroll').show();
            }
            if (tamanhoViewport < 900) {
                $('.logo').css('display', 'block');
                $('.buscaDaHome').css('display', 'block').removeClass('fixed-top');
                $('.buscaDaHome .bxSearch').css('display', 'block');;
                if ($(document).scrollTop() <= 500) {
                    $('.buscaDaHome').show().removeClass('fixed-top');
                    $('.buscaDaHome .bxSearch').show();
                } else {
                    $('.buscaDaHome').show().removeClass('fixed-top');
                    $('.buscaDaHome .bxSearch').show();
                }
            }
        } else {
            if (tamanhoViewport < 900) {
                $('.buscaDaHome').removeClass('fixed-top');
            } else {
                if ($(document).scrollTop() <= 400) {
                    $('.buscaDaHome').removeClass('fixed-top');
                    $('.logo-scroll').hide();
                    $('.buscaDaHome .bxSearch').show();
                    $('.header .spacerfix').hide();
                }
                else {
                    $('.buscaDaHome').addClass('fixed-top');
                    $('.buscaDaHome.fixed-top').slideDown();
                    $('.logo-scroll').show();
                    $('.header .spacerfix').show();
                }
            }
        }
    });
});

$(document).ready(function () {
    $('.btn-fixed-search-mobile button').on('click', function () {
        $('.bxSearch').slideToggle();
    });
});


Dropzone.autoDiscover = false;