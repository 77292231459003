$(document).ready(function (handler) {

    var $dropzoneWrapper = document.querySelector('[data-target-url]');
    if($dropzoneWrapper) {
        var $submitLink = $dropzoneWrapper.getAttribute('data-target-url');
        var $dropzone = new Dropzone(document.querySelector('.dropzone'), {
            url: $submitLink,
            dictDefaultMessage: 'Clique ou arraste os arquivos até essa área.',
            headers: {'X-CSRF-Token': readCookie('csrfToken')},
            maxFilesize: 2,
            parallelUploads: 1,
            acceptedFiles: '.jpg,.jpeg,.png,.pdf'
        });

        var $btn = $('[data-dropzone-target]').closest('form').find('button[type=submit]');

        var i = 0;

        $dropzone.on('addedfile', function (file) {
            $btn.prop('disabled', true);
        });
        $dropzone.on('sending', function (file) {
            $btn.prop('disabled', true);
        });
        $dropzone.on('queuecomplete', function (file) {
            $btn.prop('disabled', false);
        });

        $dropzone.on('success', function (file, data) {
            try {
                var $dataToAppend = '<input type="hidden" name="files[_ids][' + i + ']" value="' + data.file.payload.id + '">';
                $('[data-dropzone-target]').append($dataToAppend);
                i++;
            } catch (exception) {
                console.warn(exception.message);
                alert('Algo errado aconteceu com o seu upload. Tente novamente!');
            }
        });

        $dropzone.on('error', function (file) {
            if (2 < (parseInt(file.upload.total) / (1024 * 1024)).toFixed(0)) {
                $('#dropzone-message').show().find('ul').append('<li>O arquivo <em>' + file.name + '</em> é muito grande! O tamanho máximo é de 2 MiB</li>');
            } else {
                $('#dropzone-message').show().find('ul').append('<li>Falha ao enviar o arquivo <em>' + file.name + '</em>. Tente novamente!</li>');
            }
            $dropzone.removeFile(file);
        });
    }
});
