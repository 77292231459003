$(document).ready(function (event) {
    /**
     * Masks
     */
    var PhoneMaskBehavior = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        options = {
            onKeyPress: function (val, e, field, options) {
                field.mask(PhoneMaskBehavior.apply({}, arguments), options);
            }
        };

    $('[data-phone-mask]').mask(PhoneMaskBehavior, options);
    $('[data-currency-mask]').mask('#.##0.000,00', {reverse: true});
    $('[data-cpf-mask]').mask('000.000.000-00', {clearIfNotMatch: true});
    $('[data-postal-code]').mask('00000-000', {clearIfNotMatch: true}).on('keyup', function () {
        if ($(this).val().length === 9) {
            $(this).blur();
        }
    });
});