var comboGoogleTradutor = null; //Varialvel global

function googleTranslateElementInit() {
    new google.translate.TranslateElement({
        pageLanguage: 'pt',
        includedLanguages: 'pt,en,es,fr',
        layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL
    }, 'google_translate_element');

    comboGoogleTradutor = document.getElementById("google_translate_element").querySelector(".goog-te-combo");
}

function changeEvent(el) {
    if (el.fireEvent) {
        el.fireEvent('onchange');
    } else {
        var evObj = document.createEvent("HTMLEvents");

        evObj.initEvent("change", false, true);
        el.dispatchEvent(evObj);
    }
}

function trocarIdioma(sigla) {
    if (comboGoogleTradutor) {
        comboGoogleTradutor.value = sigla;
        changeEvent(comboGoogleTradutor);//Dispara a troca
    }
}


$(document).ready(function () {

    /* LIGHTBOX */
    $(function () {
        $('.lightImovel').Chocolat({
            imageSize: 'contain'
        });
    });

    $(".menu_btn").click(function () {
        $(".menu").toggleClass("mostrar");
        $(".bgMenu").slideToggle();
    });
    $(".bgMenu").click(function () {
        $(".menu").toggleClass("mostrar");
        $(".bgMenu").slideToggle();
    });

    $(".bxSelecionarBusca .radioMrd").on('click', function (event) {

        var dormsModal = $('[data-remodal-id=dormitorios]').remodal();
        var suitesModal = $('[data-remodal-id=suites]').remodal();
        var vagasModal = $('[data-remodal-id=vagas]').remodal();
        dormsModal.close();
        suitesModal.close();
        vagasModal.close();
    });

    /* LISTA IMÓVEIS */
    $('.btLista').click(function () {
        $(".btLista").removeClass("select");
        $(".btLista").addClass("select");
        $(".btBox").removeClass("select");
        $(".bximoveis").addClass("imovelLista");


    });

    $('.btBox').click(function () {
        $(".btBox").removeClass("select");
        $(".btBox").addClass("select");
        $(".btLista").removeClass("select");
        $(".bximoveis").addClass("imovelLista");
        $(".bximoveis").removeClass("imovelLista");
    });

    /* COMPRA VENDA */
    $(".btOpcao label").click(function () {
        $(".btOpcao label").removeClass("RadioChecked");
        $(this).addClass("RadioChecked");
    });

    /* COMPRA VENDA */
    $(".btFotos label").click(function () {
        $(".btFotos label").removeClass("RadioChecked");
        $(this).addClass("RadioChecked");
    });

    /* CHECKTIPO */
    window.checkTipo = $('.checkTipo').SumoSelect({/*okCancelInMulti:true, */selectAll: true});

    $(".btImovelSelecionar").click(function(){
       $(this).toggleClass("selecionado");
    });

    $(".btImovelComparar").click(function(){
       $(this).toggleClass("selecionado");
    });
	

	 
    function sliderImoveisList() {
        $('.slidefot').slick({
            dots: false,
            draggable: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1
        });
    }

    function sliderImoveisList2() {
        $('.slidefot2').slick({
            dots: false,
            draggable: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1
        });
    }

   


});


$(document).ready(function () {
	
	var $baseUrl = '';
                var $favorito = $('.favorito');


                /**
                 * Adicionar aos favoritos
                 */
                $favorito.on('click', '.btImovelSelecionar:not(.selecionado)', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/adicionar-favoritos/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        },
                    }).done(function (data, textStatus, jqXHR) {
                        qtd_total_favoritos = qtd_total_favoritos + 1;
                        $(".qtd_favoritos").html(qtd_total_favoritos);
                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");
                });

                /**
                 * Remover aos favoritos
                 */
                $favorito.on('click', '.btImovelSelecionar.selecionado', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/remover-favoritos/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        }
                    }).done(function (data, textStatus, jqXHR) {
                        qtd_total_favoritos = qtd_total_favoritos - 1;
                        $(".qtd_favoritos").html(qtd_total_favoritos);

                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");
                });


                /********************Comparar Imoveis*************************************/
                    //Favoritos
                var $baseUrl = '';
                var $comparar = $('.comparar');
                /**
                 * Adicionar aos Comparar
                 */
                $comparar.on('click', '.btImovelComparar:not(.selecionado)', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/adicionar-comparar/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        },
                    }).done(function (data, textStatus, jqXHR) {
                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");
                });

                /**
                 * Remover aos Comparar
                 */
                $comparar.on('click', '.btImovelComparar.selecionado', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/remover-comparar/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        }
                    }).done(function (data, textStatus, jqXHR) {
                        qtd_total_favoritos = qtd_total_favoritos - 1;
                        $(".qtd_favoritos").html(qtd_total_favoritos);

                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");

                });
	
    //Favoritos Remover Box
    $(".removerFavoritos").click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        var $this = $(this);
        var id_box_remover = $(this).data('id');
        $.ajax({
            url: '/remover-favoritos/' + $(this).data('id'),
            type: 'post',
            dataType: 'json',
            headers: {
                'X-CSRF-Token': readCookie('csrfToken')
            }
        }).done(function (data, textStatus, jqXHR) {
            qtd_total_favoritos = qtd_total_favoritos - 1;
            $(".qtd_favoritos").html(qtd_total_favoritos);
            var id_final = "imo_" + id_box_remover;
            $('#' + id_final).remove();

        }).fail(function (jqXHR, textStatus, errorThrown) {
            alert('Erro');
        });

    });

    //Comparar Remover Box
    $(".removerComparar").click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        var $this = $(this);
        var id_box_remover = $(this).data('id');
        $.ajax({
            url: '/remover-comparar/' + $(this).data('id'),
            type: 'post',
            dataType: 'json',
            headers: {
                'X-CSRF-Token': readCookie('csrfToken')
            }
        }).done(function (data, textStatus, jqXHR) {
            var id_final = "imo_" + id_box_remover;
            $('#' + id_final).remove();

        }).fail(function (jqXHR, textStatus, errorThrown) {
            alert('Erro');
        });

    });


    /* SUPER DESTAQUES */
    function sliderSuperDestaque() {
        $('.slideSuperDestaque').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });


    }

    var boxItens = $('.slideSuperDestaque');
    var loading = $('.loading');
    $.ajax({
        url: "/get-destaques",
        dataType: 'html',
        beforeSend: function () {
            loading.show();
        },
        success: function (jqXHR) {
            var tempo = setTimeout(function () {
                boxItens.html(jqXHR);
                sliderSuperDestaque();
                loading.hide();
                var $baseUrl = '';
                var $favorito = $('.favorito');


                /**
                 * Adicionar aos favoritos
                 */
                $favorito.on('click', '.btImovelSelecionar:not(.selecionado)', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/adicionar-favoritos/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        },
                    }).done(function (data, textStatus, jqXHR) {
                        qtd_total_favoritos = qtd_total_favoritos + 1;
                        $(".qtd_favoritos").html(qtd_total_favoritos);
                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");
                });

                /**
                 * Remover aos favoritos
                 */
                $favorito.on('click', '.btImovelSelecionar.selecionado', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/remover-favoritos/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        }
                    }).done(function (data, textStatus, jqXHR) {
                        qtd_total_favoritos = qtd_total_favoritos - 1;
                        $(".qtd_favoritos").html(qtd_total_favoritos);

                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");
                });


                /********************Comparar Imoveis*************************************/
                    //Favoritos
                var $baseUrl = '';
                var $comparar = $('.comparar');
                /**
                 * Adicionar aos Comparar
                 */
                $comparar.on('click', '.btImovelComparar:not(.selecionado)', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/adicionar-comparar/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        },
                    }).done(function (data, textStatus, jqXHR) {
                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");
                });

                /**
                 * Remover aos Comparar
                 */
                $comparar.on('click', '.btImovelComparar.selecionado', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/remover-comparar/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        }
                    }).done(function (data, textStatus, jqXHR) {
                        qtd_total_favoritos = qtd_total_favoritos - 1;
                        $(".qtd_favoritos").html(qtd_total_favoritos);

                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");

                });


               


            }, 500);
        },
        error: function () {
            loading.hide();
            boxItens.html('<div class="none">Não há itens a serem exibidos</div>');
        }
    });
});

/* AUTOCOMPLETE */


$(function () {
    $.widget("custom.catcomplete", $.ui.autocomplete, {
        _create: function () {
            this._super();
            this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
        },
        _renderMenu: function (ul, items) {
            var that = this,
                currentCategory = "";
            $.each(items, function (index, item) {
                var li;
                if (item.category != currentCategory) {
                    ul.append("<li class='ui-autocomplete-category'>" + item.category + "</li>");
                    currentCategory = item.category;
                }
                li = that._renderItemData(ul, item);
                if (item.category) {
                    li.attr("aria-label", item.category + " : " + item.label);
                }
            });
        }
    });
    var data = [
        {label: "Santana", category: "Bairro"},
        {label: "Morumbi", category: "Bairro"},
        {label: "Vila Guilherme", category: "Bairro"},
        {label: "São Paulo", category: "Cidade"},
        {label: "Guarulhos", category: "Cidade"},
        {label: "Campinas", category: "Cidade"}
    ];

    var $searchBox = $('[name=local]');
    var temp;
    $searchBox.catcomplete({
        minLength: 2,
        autoFocus: true,
        delay: 0,
        source: function (request, response) {
            if (temp) {
                clearTimeout(temp)
            }
            temp = setTimeout(function () {
                $.ajax({
                    url: "/properties/locations/" + request.term,
                    dataType: 'json',
                    async: false,
                    success: function (data) {
                        response(data.result);
                    },
                    complete: function () {
                        $('#loading-search').hide();
                    }
                });
            }, 1300);
            $('#loading-search').show();
        },
        change: function (event, ui) {
            if (ui.item) {
                return;
            }
            event.target.value = '';
        },
        close: function (event, ui) {
            $searchBox.trigger('change');
        }
    });
});


/* RANGE SLIDER */

$(document).ready(function () {
    $("#slider-range").slider({
        range: true,
        min: 0,
        max: 3000000,
        step: 100000.000,
        values: [0, 3000000],
        slide: function (event, ui) {

            $("#amount").val("R$" + ui.values[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."));
            $("#amount2").val("R$" + ui.values[1].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."));

            $("#amount3").val(ui.values[0]);
            $("#amount4").val(ui.values[1]);
        }
    });
    $("#amount").val("R$" + $("#slider-range").slider("values", 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."));
    $("#amount2").val("R$" + $("#slider-range").slider("values", 1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."));

    $("#amount3").val($("#slider-range").slider("values", 0));
    $("#amount4").val($("#slider-range").slider("values", 1));
});


$(function () {
    var realvalues = [0, 5, 10, 15, 30, 45, 60, 75, 90, 120, 150, 180, 210, 240, 270, 300, 500, 700, 1000, 2000, 4000, 6000, 8000, 10000];

    $("#slider-range-area").slider({
        range: true,
        min: 0,
        max: 23,
        step: 1,
        values: [0, 23],
        slide: function (event, ui) {
            $("#amount5").val(realvalues[ui.values[0]] + " M²");
            $("#amount6").val(realvalues[ui.values[1]] + " M²");

            $("#amount7").val(realvalues[ui.values[0]]);
            $("#amount8").val(realvalues[ui.values[1]]);

        }
    });
    $("#amount5").val(realvalues[$("#slider-range-area").slider("values", 0)] + " M²");
    $("#amount6").val(realvalues[$("#slider-range-area").slider("values", 1)] + " M²");

    $("#amount7").val(realvalues[$("#slider-range-area").slider("values", 0)]);
    $("#amount8").val(realvalues[$("#slider-range-area").slider("values", 1)]);

});


/* TABS */

$(document).ready(function () {

    $(".tab_content").hide();
    $("ul.tabs li:first").addClass("active").show();
    $(".tab_content:first").show();

    $("ul.tabs li").click(function () {
        $("ul.tabs li").removeClass("active");
        $(this).addClass("active");
        $(".tab_content").hide();

        var activeTab = $(this).find("a").attr("href");
        $(activeTab).fadeIn();
        $('.slideVenda').slick('setPosition');
        $('.slideLocacao').slick('setPosition');
        $('.slideFotosList').slick('setPosition');

        return false;
    });

});


/* SLIDE */

$(document).ready(function () {

    $('.slideServicos').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.slidefot').slick({
        dots: false,
        draggable: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.slideComparar').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1260,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.slidefotImoveis').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    });


});

$(document).on('opening', '.remodal', function () {
    console.log('opening');
});
$(document).on('opened', '.remodal', function () {
    console.log('opened');
});
$(document).on('closing', '.remodal', function (e) {
    console.log('closing' + (e.reason ? ', reason: ' + e.reason : ''));
});
$(document).on('closed', '.remodal', function (e) {
    console.log('closed' + (e.reason ? ', reason: ' + e.reason : ''));
});
$(document).on('confirmation', '.remodal', function () {
    console.log('confirmation');
});
$(document).on('cancellation', '.remodal', function () {
    console.log('cancellation');
});
$('[data-remodal-id=modal2]').remodal({
    modifier: 'with-red-theme'
});


/* PLACEHOLDER */
$(document).ready(function () {
    function add() {
        if ($(this).val() == '') {
            $(this).val($(this).attr('placeholder')).addClass('placeholder');
        }
    }

    function remove() {
        if ($(this).val() == $(this).attr('placeholder')) {
            $(this).val('').removeClass('placeholder');
        }
    }

    if (!('placeholder' in $('<input>')[0])) { // Create a dummy element for feature detection
        $('input[placeholder], textarea[placeholder]').blur(add).focus(remove).each(add); // Select the elements that have a placeholder attribute
        $('form').submit(function () {
            $(this).find('input[placeholder], textarea[placeholder]').each(remove);
        }); // Remove the placeholder text before the form is submitted
    }
});
