$(document).ready(function () {

    /**
     * Slider Você pode gostar, dentro da página do imóvel
     */
    $('.slideGostar').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    /**
     * Slider blog rodapé home
     */
    $('.slideDicas').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

	$('.slideImovelPage').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  dots: false,
	  infinite: false,
	  speed: 300,
	  asNavFor: '.slider-nav'
	});
	
	$('.slider-nav').slick({
	  slidesToShow: 3,
	  slidesToScroll: 1,
	  asNavFor: '.slideImovelPage',
	  dots: false,
	  arrows: false,
	  centerMode: true,
	  focusOnSelect: true
	});



	 
	 

    /* LOAD SLIDERS */
    function sliderDestaquesVenda(item) {
        $('#box-itens.' + item).slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 750,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }

    function sliderDestaquesLocacao(item) {
        $('#box-itens2.' + item).slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 750,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }

    
	
	 
    function carregarImoveisVenda(event) {
        event.preventDefault();

        var boxItens = $('#box-itens');
        var $this = $(this);
        var url = $this.attr('href');
        var dataItem = $this.data('item');
        var loading = $('.loading');

        boxItens.removeClass();
        boxItens.addClass(dataItem + ' slideImovel');

        $.ajax({
            url: url,
            dataType: 'html',
            beforeSend: function () {
                loading.show();
            },
            success: function (jqXHR) {
                var tempo = setTimeout(function () {
                    boxItens.html(jqXHR);
                    sliderDestaquesVenda(dataItem);
                    loading.hide();
					
					var $baseUrl = '';
                    var $favorito = $('.favorito');


					/**
					 * Adicionar aos favoritos
					 */
					$favorito.on('click', '.btImovelSelecionar:not(.selecionado)', function (event) {
						event.preventDefault();
						event.stopPropagation();
						var $this = $(this);
						$.ajax({
							url: '/adicionar-favoritos/' + $(this).data('id'),
							type: 'post',
							dataType: 'json',
							headers: {
								'X-CSRF-Token': readCookie('csrfToken')
							},
						}).done(function (data, textStatus, jqXHR) {
							qtd_total_favoritos = qtd_total_favoritos + 1;
							$(".qtd_favoritos").html(qtd_total_favoritos);
						}).fail(function (jqXHR, textStatus, errorThrown) {
							alert('Erro');
						});
	
						$(this).toggleClass("selecionado");
					});
	
					/**
					 * Remover aos favoritos
					 */
					$favorito.on('click', '.btImovelSelecionar.selecionado', function (event) {
						event.preventDefault();
						event.stopPropagation();
						var $this = $(this);
						$.ajax({
							url: '/remover-favoritos/' + $(this).data('id'),
							type: 'post',
							dataType: 'json',
							headers: {
								'X-CSRF-Token': readCookie('csrfToken')
							}
						}).done(function (data, textStatus, jqXHR) {
							qtd_total_favoritos = qtd_total_favoritos - 1;
							$(".qtd_favoritos").html(qtd_total_favoritos);
	
						}).fail(function (jqXHR, textStatus, errorThrown) {
							alert('Erro');
						});
	
						$(this).toggleClass("selecionado");
					});


                /********************Comparar Imoveis*************************************/
                    //Favoritos
                var $baseUrl = '';
                var $comparar = $('.comparar');
                /**
                 * Adicionar aos Comparar
                 */
                $comparar.on('click', '.btImovelComparar:not(.selecionado)', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/adicionar-comparar/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        },
                    }).done(function (data, textStatus, jqXHR) {
                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");
                });

                /**
                 * Remover aos Comparar
                 */
                $comparar.on('click', '.btImovelComparar.selecionado', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/remover-comparar/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        }
                    }).done(function (data, textStatus, jqXHR) {
                        qtd_total_favoritos = qtd_total_favoritos - 1;
                        $(".qtd_favoritos").html(qtd_total_favoritos);

                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");

                });
				
				
                }, 500);
				
            },
            error: function () {
                loading.hide();
                boxItens.html('<div class="none">Não há itens a serem exibidos</div>');
            }
        });
    }

    $('.menuTipoVenda h3 a').on('click', carregarImoveisVenda);
    $('.menuTipoVenda h3 a.active').click();

    function carregarImoveisLocacao(event) {
        event.preventDefault();

        var boxItens = $('#box-itens2');
        var $this = $(this);
        var url = $this.attr('href');
        var dataItem = $this.data('item');
        var loading = $('.loading');

        boxItens.removeClass();
        boxItens.addClass(dataItem + ' slideImovel');

        $.ajax({
            url: url,
            dataType: 'html',
            beforeSend: function () {
                loading.show();
            },
            success: function (jqXHR) {
                var tempo = setTimeout(function () {
                    boxItens.html(jqXHR);
                    sliderDestaquesLocacao(dataItem);
                    loading.hide();
					
					var $baseUrl = '';
                var $favorito = $('.favorito');


                /**
                 * Adicionar aos favoritos
                 */
                $favorito.on('click', '.btImovelSelecionar:not(.selecionado)', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/adicionar-favoritos/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        },
                    }).done(function (data, textStatus, jqXHR) {
                        qtd_total_favoritos = qtd_total_favoritos + 1;
                        $(".qtd_favoritos").html(qtd_total_favoritos);
                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");
                });

                /**
                 * Remover aos favoritos
                 */
                $favorito.on('click', '.btImovelSelecionar.selecionado', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/remover-favoritos/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        }
                    }).done(function (data, textStatus, jqXHR) {
                        qtd_total_favoritos = qtd_total_favoritos - 1;
                        $(".qtd_favoritos").html(qtd_total_favoritos);

                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");
                });


                /********************Comparar Imoveis*************************************/
                    //Favoritos
                var $baseUrl = '';
                var $comparar = $('.comparar');
                /**
                 * Adicionar aos Comparar
                 */
                $comparar.on('click', '.btImovelComparar:not(.selecionado)', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/adicionar-comparar/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        },
                    }).done(function (data, textStatus, jqXHR) {
                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");
                });

                /**
                 * Remover aos Comparar
                 */
                $comparar.on('click', '.btImovelComparar.selecionado', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var $this = $(this);
                    $.ajax({
                        url: '/remover-comparar/' + $(this).data('id'),
                        type: 'post',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-Token': readCookie('csrfToken')
                        }
                    }).done(function (data, textStatus, jqXHR) {
                        qtd_total_favoritos = qtd_total_favoritos - 1;
                        $(".qtd_favoritos").html(qtd_total_favoritos);

                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        alert('Erro');
                    });

                    $(this).toggleClass("selecionado");

                });
				
				
                }, 500);
            },
            error: function () {
                loading.hide();
                boxItens.html('<div class="none">Não há itens a serem exibidos</div>');
            }
        });
    }

    $('.menuTipoLocacao h3 a').on('click', carregarImoveisLocacao);
    $('.menuTipoLocacao h3 a.active').click();




    function sliderDestaquesGostar() {
        $('.slideVoceGostar').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 750,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }

    var boxItens = $('.slideVoceGostar');
    var loading = $('.loading');
    $.ajax({
        url: boxItens.data('url'),
        dataType: 'html',
        beforeSend: function () {
            loading.show();
        },
        success: function (jqXHR) {
            var tempo = setTimeout(function () {
                boxItens.html(jqXHR);
                sliderDestaquesGostar();
                loading.hide();
            }, 500);
        },
        error: function () {
            loading.hide();
            boxItens.html('<div class="none">Não há itens a serem exibidos</div>');
        }
    });
});