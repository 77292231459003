// UI Slider Bar - Preço do Imóvel
function addDecimal(nStr) {
    nStr += '';
    x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
}

function closeModal($this) {
    try {
        var $modal = $this.closest('.remodal').remodal();
        $modal.close();
    } catch (exception) {
        console.warn(exception.message);
    }
}

$(document).ready(function () {

    /**
     * Rádio style for options
     */
    $(".btOpcao label").click(function () {
        $(".btOpcao label").removeClass("RadioChecked");
        $(this).addClass("RadioChecked");
    });

    $(".btFotos label").click(function () {
        $(".btFotos label").removeClass("RadioChecked");
        $(this).addClass("RadioChecked");
    });

    /**
     * Tipo select
     * @type {jQuery}
     */
    $('.checkTipo').SumoSelect({selectAll: true});

    /* BUSCA AVANÇADA */
    $(".btBuscaAvancada p").click(function () {
        $(".bxBuscaRapida").toggleClass("mostrar");
        $(".contentHome").toggleClass("mostrar");
        $(".bxBuscaAvancada").slideToggle();
    });

    /**
     * SHOW ADVANCED SEARCH
     */
    $(".btShowSearch").click(function () {
        $(".bxSearch").slideToggle();
    });

    $('input[name=set-dorms]').on('change', function (event) {
        event.preventDefault();

        $('[data-dorms-target]').trigger('change').val($('input[name="set-dorms"]:checked').val());
        closeModal($(this));
    });

    $('input[name=set-suites]').on('change', function (event) {
        event.preventDefault();

        $('[data-suite-target]').trigger('change').val($('input[name="set-suites"]:checked').val());
        closeModal($(this));
    });

    $('input[name=set-vagas]').on('change', function (event) {
        event.preventDefault();

        $('[data-vagas-target]').trigger('change').val($('input[name="set-vagas"]:checked').val());
        closeModal($(this));
    });

    /**
     * Slider values
     * @type {jQuery|HTMLElement}
     */

    var $sliderValue = $('[data-slider-range-value]');

    if ($sliderValue.length) {

        var $businessType = $('[name=operacao]');
        var $valueMin = $('[data-slider-value-min]');
        var $valueMax = $('[data-slider-value-max]');
        var $values = $('[data-slider-values]');
        var $rangeValueMin = function () {
            return ('comprar' === $('[name=operacao]:checked').val()) ? 100000 : 200;
        };
        var $rangeValueMax = function () {
            return ('comprar' === $('[name=operacao]:checked').val()) ? 3000000 : 30000;
        };
        var $rangeStep = function () {
            return ('comprar' === $('[name=operacao]:checked').val()) ? 100000.000 : 100.000;
        };
        var $rangeValues = function () {
            return ('comprar' === $('[name=operacao]:checked').val()) ? [100000, 3000000] : [200, 30000];
        };

        try {
            $sliderValue.slider({
                range: true,
                min: $rangeValueMin(),
                max: $rangeValueMax(),
                step: $rangeStep(),
                values: $rangeValues(),
                slide: function (event, ui) {
                    var $min = 'R$' + addDecimal(ui.values[0]) + ',00';
                    var $max = 'R$' + addDecimal(ui.values[1]) + ',00';
                    var $val = $min + ' - ' + $max;
                    $valueMin.is('input') ? $valueMin.val($min) : $valueMin.empty().text($min);
                    $valueMax.is('input') ? $valueMax.val($max) : $valueMax.empty().text($max);
                    $values.is('input') ? $values.trigger('change').val($val) : $values.empty().text($val);
                }
            });
        } catch (exception) {
            console.warn('Falha ao inicializar o slider de seleção de preço');
            console.warn(exception.message);
        }

        $businessType.on('change', function () {
                $sliderValue.slider('option', 'min', $rangeValueMin());
                $sliderValue.slider('option', 'max', $rangeValueMax());
                $sliderValue.slider('option', 'values', $rangeValues());
                $sliderValue.slider('option', 'step', $rangeStep());
                if ($values.val() !== '') {
                    $valueMin.val('R$' + addDecimal($rangeValueMin()) + ',00');
                    $valueMax.val('R$' + addDecimal($rangeValueMax()) + ',00');
                    var $strValue = 'R$' + addDecimal($rangeValueMin()) + ',00 - R$' + addDecimal($rangeValueMax()) + ',00';
                    $values.val($strValue);
                }
            }
        );
    }

    var $sliderArea = $('[data-slider-range-area]');

    if (!$sliderArea.length) {
    } else {

        var $areaMin = $('[data-slider-area-min]');
        var $areaMax = $('[data-slider-area-max]');
        var $area = $('[data-slider-area]');
        var realvalues = [0, 5, 10, 15, 30, 45, 60, 75, 90, 120, 150, 180, 210, 240, 270, 300, 500, 700, 1000, 2000, 4000, 6000, 8000, 10000];

        try {
            $sliderArea.slider({
                range: true,
                min: 0,
                max: 23,
                step: 1,
                values: [0, 23],
                slide: function (event, ui) {
                    var $min = realvalues[addDecimal(ui.values[0])] + 'm²';
                    var $max = realvalues[addDecimal(ui.values[1])] + 'm²';
                    var $values;
                    $values = $min + ' - ' + $max;

                    $areaMin.is('input') ? $areaMin.val($min) : $areaMin.empty().text($min);
                    $areaMax.is('input') ? $areaMax.val($max) : $areaMax.empty().text($max);
                    $area.is('input') ? $area.trigger('change').val($values) : $area.empty().text($values);
                }
            });
        } catch (exception) {
            console.warn('Falha ao inicializar o slider de seleção de preço');
            console.warn(exception.message);
        }
    }

    $('.clearField').on('click', function (event) {
        $(this).parent().siblings('input').trigger('change').val('');
    });

    /**
     * Show how many properties are available when user start to select the options
     */
    $('form.bxSearch input:not([name=ref]),form.bxSearch select').on('change', function (event) {
        var $resultBox = $('#search-number-result');
        $.ajax({
            url: $resultBox.data('search-number-link'),
            type: 'get',
            dataType: 'json',
            data: $('form.bxSearch').serialize(),
            beforeSend: function (jqXHR, settings) {
                $resultBox.find('#show-result').empty();
                $resultBox.find('#loading-search').show();
            }
        }).done(function (data, textStatus, jqXHR) {
            try {
                var properties = data.properties;
                if (properties.success) {
                    var message;
                    if (properties.payload === 0) {
                        message = 'Nenhum imóvel encontrado.';
                    } else if (properties.payload === 1) {
                        message = '<span>1</span> imóvel encontrado.';
                    } else if (properties.payload > 1) {
                        message = '<span>' + properties.payload + '</span> imóveis encontrados'
                    }
                    $resultBox.find('#show-result').html(message);
                }
            } catch (exception) {
                console.warn(exception.message);
            }
        }).always(function () {
            $resultBox.find('#loading-search').hide();
        });
    });

    /**
     * Clear number case user try to search by code
     */
    $('form.bxSearch input[name=ref]').on('focus keyup', function (event) {
        $('#search-number-result').find('#show-result').empty();
    });

    $('form.bxSearch').on('submit', function (event) {
        $this = $(this);
        $.each($(this).serializeArray(), function (key, value) {
            if (value.name === 'ref' && value.value !== '') {
                event.preventDefault();
                $.ajax({
                    url: $this.data('search-by-code-url'),
                    type: 'get',
                    dataType: 'json',
                    data: {imovelCodigo: value.value}
                }).done(function (data, textStatus, jqXHR) {
                    window.location.replace(data.redirUrl);
                }).fail(function () {
                    alert('Imóvel com código *' + value.value + '* não encontrado');
                })
            }
        });
    });

    $('<span></span>').insertAfter('input[type=radio].radioMrd ');

    $('.bxSearch .btSelect .form-group.select').css('display', 'block');
});
